// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
        <svg width="175" height="33" version="1.1" viewBox="0 0 1167 217" xmlns="http://www.w3.org/2000/svg">
            <g transform="matrix(.43403 0 0 .43403 25 -2.7778)">
                <path
                    d="m572.69 292.09-72.424 124.16a63.997 63.997 0 0 1-55.281 31.752h-399.96c-18.523 0-30.064-20.093-20.731-36.093l72.424-124.16a64 64 0 0 1 55.282-31.752h399.96c18.523 0 30.064 20.093 20.73 36.093z"
                    fill={theme.palette.secondary.main}
                />
                <path
                    d="m152 224h328v-48c0-26.51-21.49-48-48-48h-160l-64-64h-160c-26.51 0-48 21.49-48 48v278.05l69.077-118.42c17.137-29.378 48.912-47.628 82.923-47.628z"
                    fill={theme.palette.primary.main}
                />
            </g>
            <text x="319.61807" y="173.95827">
                <tspan x="319.61807" y="173.95827" fontFamily="Raleway" fontWeight="bold" strokeWidth="4.3403" fontSize="173.61px">
                    CARDFILE
                </tspan>
            </text>
        </svg>
    );
};

export default Logo;
