import {
    CARDFILE_REFRESH,
    SELECT_CARDITEM,
    SET_ADDCARDSTACK,
    SET_API_HANDLER,
    SET_CARDITEMDETAILSEDIT,
    SET_CARDITEMDETAILSOPEN,
    SET_CARDFILES,
    SET_CARDITEMS,
    SET_CARDSTACKS,
    SET_NEWCARDITEMDETAILS,
    UPDATE_CARDSTACK_POSITION,
    UPDATE_CURRENT_CARDFILE
} from './actions';

const initialState = {
    addCardstackOpen: false,
    api: false,
    cardfiles: [],
    cardfileRefresh: 0,
    carditemDetailsEdit: false,
    carditemDetailsOpen: false,
    carditems: [],
    carditemsPosition: [],
    cardstacks: [],
    cardstacksPosition: [],
    currentCardfile: false,
    newCarditemOpen: false,
    selectedCarditem: false,
    userToken: ''
};

const cardfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case CARDFILE_REFRESH:
            return {
                ...state,
                cardfileRefresh: state.cardfileRefresh + 1
            };
        case SELECT_CARDITEM:
            return {
                ...state,
                selectedCarditem: action.payload.selectedCarditem
            };
        case SET_ADDCARDSTACK:
            return {
                ...state,
                addCardstackOpen: action.payload.addCardstackOpen
            };
        case SET_API_HANDLER:
            return {
                ...state,
                user: action.payload.user,
                api: action.payload.api,
                apiSet: true
            };
        case SET_CARDITEMDETAILSOPEN:
            return {
                ...state,
                carditemDetailsOpen: action.payload.carditemDetailsOpen
            };
        case SET_CARDFILES:
            return {
                ...state,
                cardfiles: action.payload.cardfiles
            };
        case SET_CARDITEMS:
            return {
                ...state,
                carditems: action.payload.carditems,
                carditemsPosition: action.payload.carditemsPosition
            };
        case SET_CARDSTACKS:
            return {
                ...state,
                cardstacks: action.payload.cardstacks,
                cardstacksPosition: action.payload.cardstacksPosition
            };
        case SET_CARDITEMDETAILSEDIT:
            return {
                ...state,
                carditemDetailsEdit: action.payload.carditemDetailsEdit
            };
        case SET_NEWCARDITEMDETAILS:
            return {
                ...state,
                newCarditemOpen: action.payload.newCarditemOpen
            };
        case UPDATE_CURRENT_CARDFILE:
            return {
                ...state,
                currentCardfile: action.payload.currentCardfile
            };
        case UPDATE_CARDSTACK_POSITION:
            return {
                ...state,
                cardstacksPosition: action.payload.cardstacksPosition
            };
        default:
            return state;
    }
};

export default cardfileReducer;
