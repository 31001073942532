const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '/user',
    homePath: '/user',
    cfPath: '/cf',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 4,
    outlinedFilled: true,
    theme: 'light', // light, dark
    presetColor: 'cardfileTheme', // default, theme1, theme2, theme3, theme4, theme5, theme6
    i18n: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    api: {
        base: process.env.REACT_APP_APISERVER,
        timeout: process.env.REACT_APP_APITIMEOUT
    },
    jwt: {
        secret: process.env.REACT_APP_JWTSECRET,
        timeout: process.env.REACT_APP_JWTTIMEOUT
    },
    auth0: {
        client_id: process.env.REACT_APP_AUTH0CLIENTID,
        domain: process.env.REACT_APP_AUTH0DOMAIN,
        audience: process.env.REACT_APP_AUTH0AUDIENCE,
        redirect_uri: process.env.REACT_APP_AUTH0REDIRECT
    }
};

export default config;
