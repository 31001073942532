import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// application routing
const AppCardfile = Loadable(lazy(() => import('views/application/cardfile')));
const AppUser = Loadable(lazy(() => import('views/application/user')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/user',
            element: <AppUser />
        },
        {
            path: '/cf',
            element: <AppCardfile />
        },
        {
            path: '/cf/:cardfileId',
            element: <AppCardfile />
        }
    ]
};

export default MainRoutes;
