// project imports
import MinimalLayout from 'layout/MinimalLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';

const AuthenticationRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MinimalLayout />
        </AuthGuard>
    )
};

export default AuthenticationRoutes;
