import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import Loadable from 'ui-component/Loadable';

const PagesLanding = Loadable(lazy(() => import('views/pages/landing')));
const Panda = Loadable(lazy(() => import('views/pages/panda')));
const Support = Loadable(lazy(() => import('views/pages/support')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([
        { path: '/', element: <PagesLanding /> },
        { path: '/panda', element: <Panda /> },
        { path: '/support', element: <Support /> },
        AuthenticationRoutes,
        LoginRoutes,
        MainRoutes
    ]);
}
